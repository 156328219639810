import React, { Component } from 'react'
import axios from 'axios'

import { Select, Card, Button, InputNumber, Input, Form } from 'antd'

import { MailOutlined, GlobalOutlined } from '@ant-design/icons'

const { Option } = Select

class Quiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: 30,
      rate: 8,
      websites: 2,
      links: 5,
      timePerLink: 40,
      cadence: 7,
      categoryRate: 5.5,
      averageRevenue: 2000,
      brokenLinkPercentage: 10,

      stepId: 0,
      email: '',
    }
  }

  getCurrentPlanName = () => {
    const { websites } = this.state
    const checks = this.getRequiredMonthyChecks()
    console.log('checks', checks)
    // const isOneWebsites = websites <= 3;
    const starterSites = 3
    const standardSites = 5
    const portfolioProjects = 10

    const needsStarterSites = websites <= starterSites
    const needsStandardSites = websites > starterSites && websites <= standardSites
    const needsPortfolioSites = websites > standardSites && websites <= portfolioProjects
    const needsTycoonSites = websites > portfolioProjects

    console.log(needsStarterSites, needsPortfolioSites)
    const starterChecks = 5000
    const standardChecks = 10000
    const portfolioChecks = 20000

    const needsStarterChecks = checks <= starterChecks
    const needsStandardChecks = checks > starterChecks && checks <= standardChecks
    const needsPortfolioChecks = checks > standardChecks && checks <= portfolioChecks
    const needsTycoonChecks = checks > portfolioChecks
    console.log(needsStarterChecks, needsStandardChecks, needsPortfolioChecks, needsTycoonChecks)
    console.log('========')

    if (needsTycoonChecks || needsTycoonSites) return 'Tycoon'
    if (needsPortfolioSites || needsPortfolioChecks) return 'Portfolio'
    if (needsStandardChecks || needsStandardSites) return 'Standard'
    return 'Starter'
  }

  getCurrentPlanCost = () => {
    const plan = this.getCurrentPlanName()
    if (plan === 'Standard') return 34.95
    if (plan === 'Portfolio') return 49.95
    if (plan === 'Tycoon') return 124.95
    return 19.95
  }

  getRequiredMonthyChecks = () => {
    const { links, websites, pages, cadence } = this.state
    return links * pages * websites * (30 / cadence)
  }

  getMonthlyMoneyLost = () => {
    const { averageRevenue, brokenLinkPercentage } = this.state
    return (averageRevenue * brokenLinkPercentage) / 100
  }

  getResult = () => {
    const { links, rate, websites, pages, timePerLink, cadence } = this.state
    return ((links * timePerLink * pages * websites) / 3600) * rate * (30 / cadence)
  }

  startAgain = () => {
    this.setState({ stepId: 0 })
  }

  submitEmail = async () => {
    const { email } = this.state
    try {
      let campaign = ''
      if (localStorage && localStorage.getItem('campaign')) {
        campaign = localStorage.getItem('campaign')
      }
      const config = { siteUrl: '', email, source: 'quiz-1', campaign }
      console.log('putting config', config)
      await axios.put(`https://app.amzwatcher.com/api/users/addToList/quiz`, config)
      this.setState({ stepId: 4 })
    } catch (e) {
      console.log('error', e)
    }
  }

  renderStep0 = () => {
    const start = () => {
      console.log('starting')
      this.setState({ stepId: 1 })
    }
    return (
      <div>
        <div onClick={start} style={{ marginBottom: 20 }}>
          <span className="button-wrapper">
            <div className="button-action button-step-0-start-quiz">
              <span>Start Quiz</span>
            </div>
          </span>
        </div>
      </div>
    )
  }

  renderStep1 = () => {
    const { links, websites, pages, cadence } = this.state

    const websiteSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(websites) => this.setState({ websites })}
        value={websites}
        size="small"
      >
        {Array(50)
          .fill(0)
          .map((c, i) =>
            i > 0 ? (
              <Option value={i} key={i}>
                {i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const pagesSelector = (
      <Select
        style={{ width: 80, minWidth: 80 }}
        onChange={(pages) => this.setState({ pages })}
        value={pages}
        size="small"
      >
        <Option value={10} key={10}>
          10
        </Option>
        <Option value={15} key={15}>
          15
        </Option>
        {Array(500)
          .fill(0)
          .map((c, i) =>
            i > 1 ? (
              <Option value={i * 10} key={i}>
                {i * 10}
              </Option>
            ) : null
          )}
      </Select>
    )

    const linksSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(links) => this.setState({ links })}
        value={links}
        size="small"
      >
        {Array(21)
          .fill(0)
          .map((c, i) =>
            i > 0 ? (
              <Option value={i} key={i}>
                {i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const cadenceSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(cadence) => this.setState({ cadence })}
        value={cadence}
        size="small"
      >
        <Option value={1}>{1}</Option>
        <Option value={3}>{3}</Option>
        <Option value={5}>{5}</Option>
        <Option value={7}>{7}</Option>
        <Option value={14}>{14}</Option>
        <Option value={28}>{28}</Option>
      </Select>
    )

    const addS = this.state.websites > 1
    console.log('current price', this.getCurrentPlanCost())

    const savings = Math.trunc(this.getMonthlyMoneyLost() - this.getCurrentPlanCost())
    console.log('savings', savings)

    return (
      <div>
        <div style={{ display: 'block', lineHeight: '40px', marginBottom: 20 }}>
          I run {websiteSelector} website{addS && 's'} with {pagesSelector} commercial articles{' '}
          {websites > 1 && '(per site on average)'}.&nbsp;
          <br />
          An average commercial article has {linksSelector} unique amazon links. &nbsp;
          <br />I want to check links every {cadenceSelector} days.
        </div>
        <div>
          {/* <Button type="dashed" disabled onClick={() => this.setState({ stepId: 1 })}>Back</Button> */}
          <Button
            type="primary"
            className="button-step-1-next"
            onClick={() => this.setState({ stepId: 2 })}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }

  renderStep2 = () => {
    const { averageRevenue, brokenLinkPercentage, email } = this.state

    const brokenLinkPercentageSelector = (
      <Select
        style={{ width: 70, minWidth: 70 }}
        onChange={(brokenLinkPercentage) => this.setState({ brokenLinkPercentage })}
        value={brokenLinkPercentage}
        size="small"
      >
        <Option value={1}>{1}%</Option>
        <Option value={2}>{2}%</Option>
        <Option value={5}>{5}%</Option>
        <Option value={10}>{10}%</Option>
        <Option value={15}>{15}%</Option>
        <Option value={20}>{20}%</Option>
      </Select>
    )

    const revenueInput = (
      <InputNumber
        defaultValue={averageRevenue}
        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        onChange={(averageRevenue) => this.setState({ averageRevenue })}
        size="small"
      />
    )

    const savings = Math.trunc(this.getMonthlyMoneyLost() - this.getCurrentPlanCost())
    console.log('savings', savings)

    const losingMoney = Math.trunc((averageRevenue * brokenLinkPercentage) / 100)

    return (
      <div>
        <div style={{ lineHeight: '40px', marginBottom: 20 }}>
          My website potfolio makes {revenueInput} per month on average.
          <br />
          If {brokenLinkPercentageSelector} of my affiliate links are broken, I am losing...
        </div>
        <div>
          <Button
            type="dashed"
            className="button-step-2-back"
            onClick={() => this.setState({ stepId: 1 })}
            style={{ marginRight: 15 }}
          >
            Back
          </Button>
          <Button
            type="primary"
            className="button-step-2-next"
            onClick={() => this.setState({ stepId: email ? 4 : 3 })}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }

  renderStep3 = () => {
    const handleSubmit = (values) => {
      this.setState({ ...values }, () => this.submitEmail())
    }

    return (
      <div>
        <div style={{ textAlign: 'center', marginBottom: 15 }}>Please Share Your Email</div>
        <Form onFinish={handleSubmit}>
          <Form.Item
            label=""
            className="login-form-item"
            name="email"
            rules={[
              { required: true, message: 'Please input your email' },
              { type: 'email', message: 'Plase input a valid email' },
            ]}
          >
            <Input
              style={{ height: '40px' }}
              className="landing-search-input"
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Your Email"
              size="large"
            />
          </Form.Item>
          <Button
            role="button"
            style={{ padding: 0, borderRadius: '3px' }}
            className="core-optin-cta quiz-optin-cta"
            // onClick={this.signup}
            htmlType="submit"
          >
            Open Results
          </Button>
        </Form>
        <div>
          <Button
            type="dashed"
            className="button-step-3-back"
            onClick={() => this.setState({ stepId: 2 })}
          >
            Back
          </Button>
          {/* <Button type="primary" onClick={() => this.setState({ stepId: 4 })}>Next</Button> */}
        </div>
      </div>
    )
  }

  renderStep4 = () => {
    const {
      links,
      rate,
      websites,
      pages,
      cadence,
      averageRevenue,
      categoryRate,
      brokenLinkPercentage,
    } = this.state

    const savings = Math.trunc(this.getMonthlyMoneyLost())
    console.log('savings', savings)

    return (
      <div>
        <span style={{ fontSize: '20px', margin: '10px 0 20px 0', fontWeight: '700' }}>
          Results:
        </span>
        {savings <= 0 && (
          <div>Try checking the links less often to see higher benefit from AMZ Watcher</div>
        )}
        {savings > 0 && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                border: '1px solid gainsboro',
                padding: 10,
                boxShadow: '0 0 13px -10px',
                margin: '20px 0',
              }}
            >
              <span style={{ fontSize: '15px', fontWeight: '600', margin: '10px 10px 0 10px' }}>
                Broken Links Revenue Impact{' '}
                <span style={{ color: 'rgb(197, 15, 66)', fontWeight: '700', display: 'block' }}>
                  -${savings} / month
                </span>
              </span>
              <br />
            </div>
            <div style={{ fontSize: '15px', fontWeight: '600', margin: '10px 0' }}>
              Fix Your Links To Get:
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                border: '1px solid gainsboro',
                padding: 10,
                boxShadow: '0 0 13px -10px',
                margin: '20px 0',
              }}
            >
              <span style={{ fontSize: '15px', fontWeight: '600', margin: '10px 0' }}>
                Valuation Increase{' '}
                <span style={{ color: 'rgb(15, 197, 74)', fontWeight: '700', display: 'block' }}>
                  +${this.getMonthlyMoneyLost() * 35}
                </span>
              </span>
              <br />
            </div>
            <span style={{ fontSize: '15px', fontWeight: '600', margin: '10px 0' }}>
              The best plan for your portfolio is{' '}
              <span style={{ color: 'rgb(15, 197, 74)', fontWeight: '700', display: 'block' }}>
                {this.getCurrentPlanName()} @ ${this.getCurrentPlanCost()} / month
              </span>
            </span>
          </div>
        )}
        <span className="button-wrapper">
          <a
            href="https://app.amzwatcher.com/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="button-action button-step-4-sign-up"
          >
            <span>Sign Up Now</span>
          </a>
        </span>
        <div style={{ color: 'grey', fontSize: 12, marginTop: 30 }}>
          Disclaimer: <br />
          * This calculator is for educational purposes only. The results will be different for
          every customer. <br />
          * This calculator does not take into account the work required to change the links. <br />
          * We use a 35x multiple to calculate your site's valuation <br />
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            type="dashed"
            className="button-step-4-start-over"
            onClick={this.startAgain}
            style={{ fontSize: 12 }}
          >
            Start Over
          </Button>
        </div>
      </div>
    )
  }

  renderStep = (stepId) => {
    switch (stepId) {
      case 0: {
        return this.renderStep0()
      }
      case 1: {
        return this.renderStep1()
      }
      case 2: {
        return this.renderStep2()
      }
      case 3: {
        return this.renderStep3()
      }
      case 4: {
        return this.renderStep4()
      }
      default: {
        return this.renderStep0()
      }
    }
  }

  render() {
    const { stepId } = this.state

    return (
      <div className="widget-form">
        <Card
          title={'Quiz: How much money are you losing from broken Amazon links?'}
          headStyle={{ fontSize: 20, textAlign: 'center' }}
          className="widget-card"
        >
          {this.renderStep(stepId)}
        </Card>
      </div>
    )
  }
}

export default Quiz
