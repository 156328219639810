import React, { Component } from 'react'

import URI from 'urijs'
import { Card, Button, Input, message, Checkbox } from 'antd'

import { CopyToClipboard } from 'react-copy-to-clipboard'

const { TextArea } = Input

const def =
  'https://www.amazon.com/Backpack-Business-Compartment-Charging-Reflective/dp/B07MG4HN96/ref=as_li_ss_tl?SubscriptionId=AKIAJO7E5OLQ67NVPFZA&ascsubtag=777642968-2-781602830.1557861815'

class LinkCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: def,
      tag: 'amzwatcher-20',
      linkText: 'See Price',
      globalize: false,
      shorten: false,
      newTab: true,
      nofollow: true,
      noopener: true,
    }
  }

  isSourceValid = () => {
    const { source } = this.state
    if (!source || source.length === 0) return false
    if (source.indexOf('http') < 0) return false
    if (source.indexOf('amazon') < 0) return false

    const amazonUrls = [
      'amazon.com',
      'amazon.co.uk',
      'amazon.es',
      'amazon.de',
      'amazon.com.au',
      'amazon.com.br',
      'amazon.ca',
      'amazon.cn',
      'amazon.fr',
      'amazon.in',
      'amazon.it',
      'amazon.co.jp',
      'amazon.com.mx',
    ]

    const isAmazonDomain = amazonUrls.some((u) => source.indexOf(u) > -1)
    if (!isAmazonDomain) return false

    return true
  }

  setDefault = () => {
    this.setState({ source: def, tag: 'amzwatcher-20', linkText: 'See Price' })
  }

  clearForm = () => {
    this.setState({ source: '', tag: '', linkText: '' })
  }

  createLink = () => {
    const { tag, source } = this.state
    if (!this.isSourceValid() || !tag) return ''
    const link = URI(source)

    const queryString = { tag }
    // i just clean all the garbage in the query string  instead of destructuring it
    // const linkQuery = link.search(true);
    // linkQuery.tag = tag;
    link.search(queryString)

    const result = link.toString()
    return result
  }

  createHTMLLink = (link) => {
    const { tag, linkText, newTab, nofollow, noopener } = this.state
    if (!link || !linkText || !tag) return ''
    const htmlLink = `<a href="${link}"${newTab ? ' target="_blank"' : ''} rel="${
      nofollow ? 'nofollow ' : ''
    }${noopener ? 'noopener' : ''}" title="${linkText}" alt="${linkText}">${linkText}</a>`
    return htmlLink
  }

  render() {
    const { source, tag, linkText, newTab, nofollow, noopener } = this.state
    const isSourceValid = source.length === 0 || this.isSourceValid()
    const finalLink = this.createLink()
    const htmlLink = this.createHTMLLink(finalLink)

    return (
      <div className="widget-form">
        <Card
          title={<span>Amazon Associates Link Creator</span>}
          headStyle={{ fontSize: 20, textAlign: 'center' }}
          className="widget-card"
          style={{ width: '70%' }}
        >
          <div style={{ textAlign: 'left', marginBottom: '20px' }}>
            <Button style={{ marginRight: '12px' }} onClick={this.setDefault}>
              Set Test Link
            </Button>
            <Button style={{ marginRight: '12px' }} onClick={this.clearForm}>
              Clear All Data
            </Button>
            <Checkbox onChange={(e) => this.setState({ shorten: e.target.checked })} disabled>
              Shorten Link (coming soon)
            </Checkbox>
            <Checkbox onChange={(e) => this.setState({ globalize: e.target.checked })} disabled>
              Globalize Link (coming soon)
            </Checkbox>
          </div>
          <div className="link-ceator">
            <div>
              <div style={{ textAlign: 'left', marginRight: '10px' }}>
                Amazon Link URL:
                <Input
                  size="large"
                  placeholder="Amazon Link URL"
                  onChange={(e) => this.setState({ source: e.target.value })}
                  value={source}
                  style={{ marginTop: '5px' }}
                  autoFocus
                />
              </div>
              {!isSourceValid && 'Link is not valid or not supported'}
            </div>
            <div style={{ textAlign: 'left', marginRight: '10px', width: 335 }}>
              Affiliate Tag:
              <Input
                size="large"
                placeholder="Affilaite Tag"
                onChange={(e) => this.setState({ tag: e.target.value })}
                value={tag}
                style={{ marginTop: '5px' }}
              />
            </div>
            <div style={{ textAlign: 'left', width: 365 }}>
              Link Text:
              <Input
                size="large"
                placeholder="Link Text"
                onChange={(e) => this.setState({ linkText: e.target.value })}
                value={linkText}
                style={{ marginTop: '5px' }}
              />
            </div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <h5>Final Link URL</h5>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <Input
                size="large"
                placeholder="Final Link URL"
                disabled
                value={finalLink}
                style={{ color: 'black', marginRight: 10 }}
              />
              <Button
                disabled={!finalLink}
                size="large"
                onClick={() => window.open(finalLink, '_blank')}
                style={{ marginRight: 10 }}
              >
                Open in new window
              </Button>
              <CopyToClipboard
                text={finalLink}
                onCopy={() => finalLink && finalLink.length > 0 && message.success('Link Copied!')}
              >
                <Button
                  size="large"
                  // className="button-action"
                  style={{ width: 350 }}
                  disabled={!finalLink}
                >
                  Copy URL To Clipboard
                </Button>
              </CopyToClipboard>
            </div>
            <br />
            <h5>HTML Link Creator</h5>
            <div style={{ textAlign: 'left', marginBottom: '10px' }}>
              <Checkbox
                onChange={(e) => this.setState({ newTab: e.target.checked })}
                checked={newTab}
              >
                Open in New Tab
              </Checkbox>
              <Checkbox
                onChange={(e) => this.setState({ nofollow: e.target.checked })}
                checked={nofollow}
              >
                rel="nofollow"
              </Checkbox>
              <Checkbox
                onChange={(e) => this.setState({ noopener: e.target.checked })}
                checked={noopener}
              >
                rel="noopener"
              </Checkbox>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <TextArea
                rows={3}
                size="large"
                placeholder="Final Link HTML"
                disabled
                value={htmlLink}
                style={{ color: 'black', marginRight: 10, height: 40 }}
              />
              <CopyToClipboard
                text={htmlLink}
                onCopy={() =>
                  htmlLink && htmlLink.length > 0 && message.success('HTML Link Copied!')
                }
              >
                <Button
                  size="large"
                  className="button-action"
                  style={{
                    color: 'white',
                    borderRadius: 2,
                    width: 400,
                    padding: 'initial',
                    fontWeight: 600,
                  }}
                  disabled={!htmlLink}
                >
                  Copy Link HTML To Clipboard
                </Button>
              </CopyToClipboard>
            </div>
            {/* <a href={finalLink} style={{ color: '#676767' }} target="_blank" rel="noopener noreferrer">{finalLink}</a> */}
          </div>

          {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <Button 
                            className="button-action" 
                            disabled={!finalLink}
                            onClick={() => window.open(finalLink, '_blank')}
                            style={{ color: 'white', marginRight: 20 }}>
                            Open in new window
                        </Button>
                    </div>
                    <CopyToClipboard
                        text={finalLink}
                        onCopy={() => finalLink && finalLink.length > 0 && message.success('Link Copied!')}
                    >
                        <span className="button-action">
                            Copy To Clipboard
                        </span>
                    </CopyToClipboard>
                </div> */}
        </Card>
      </div>
    )
  }
}

export default LinkCreator
