import React from 'react'
import ReactDOM, { render } from 'react-dom'
import './index.css'
import './App.css'

import TimeCalculator from './tools/TimeCalculator'
import RecoveryCalculator from './tools/RecoveryCalculator'
import LinkCreator from './tools/LinkCreator'
import EarningsCalculator from './tools/EarningsCalculator/EarningsCalculator'
// import SignUpModal from './tools/SignupModal';
import PricingTable from './tools/PricingTable/PricingTable'
// import ImageExtractor from './tools/ImageExtractor';
// import LinkValidator from './tools/LinkValidator';
import Quiz from './tools/Quiz'

const renderIfElInDOM = (component, id) => {
  const el = document.getElementById(id)
  console.log('found el and rendering', el)
  if (el) {
    ReactDOM.render(component, el)
  }
}

renderIfElInDOM(<RecoveryCalculator />, 'reccalcapp-root')
renderIfElInDOM(<TimeCalculator />, 'calcapp-root')
renderIfElInDOM(<LinkCreator />, 'linkcreator-root')

renderIfElInDOM(<PricingTable />, 'pricingtable-root')
renderIfElInDOM(<EarningsCalculator />, 'earningscalc-root')
renderIfElInDOM(<Quiz />, 'quiz-root')

// renderIfElInDOM(<SignUpModal />, 'signupmodal-root');
// renderIfElInDOM(<ImageExtractor />, 'imagextractor-root');
// renderIfElInDOM(<LinkValidator />, 'linkvalidator-root');
