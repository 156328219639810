import React, { Component } from 'react'

import { Typography, List, Tooltip } from 'antd'

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'

import SignupModal from './SignupModal'
import YearlySwitcher from './YearlySwitcher'
import PlanHeader from './PlanHeader'

import './PricingTable.css'

const { Title } = Typography
const { Item } = List

class PricingTable extends Component {
  state = { modalOpen: false, isYearly: true }

  onClose = () => {
    this.setState({ modalOpen: false })
  }

  getPlanProps = (key) => {
    const { isYearly } = this.state
    const plan = {}
    switch (key) {
      case 'starter':
        plan.price = isYearly ? 1663 : 1995
        plan.plan = 'Starter'
        plan.isYearly = isYearly
        break
      case 'standard':
        plan.price = isYearly ? 2996 : 3595
        plan.plan = 'Standard'
        plan.isYearly = isYearly
        break
      case 'portfolio':
        plan.price = isYearly ? 4163 : 4995
        plan.plan = 'Portfolio'
        plan.isYearly = isYearly
        break
      case 'tycoon':
        plan.price = isYearly ? 10413 : 12495
        plan.plan = 'Tycoon'
        plan.isYearly = isYearly
        break
      default:
        break
    }
    return plan
  }

  createAction = (key) => {
    return (
      <span
        role="button"
        style={{
          lineHeight: 40,
          padding: '10px 20px',
          cursor: 'pointer',
          borderRadius: '3px',
        }}
        className="core-optin-cta"
        onClick={this.signupRedirect}
        // onClick={() => this.setState({ modalOpen: true })}
      >
        Try For Free
      </span>
    )
  }

  renderPlanFeatures = (plan) => {
    const features = [
      {
        text: 'We find all out of stock, 404 and unavailable products that you link out to',
        title: 'Find Broken Amazon Links',
        allowed: ['starter', 'standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'We find all links that do not have or have wrong affiliate ids ',
        title: 'Find Wrong and Missing Affiliate Tags',
        allowed: ['starter', 'standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'We show you rating and amount of reviews a product has, so that you can remove low-quality products',
        title: 'Find Low Quality Products',
        allowed: ['starter', 'standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'Check Your Website or YouTube Channel for broken Amazon links',
        title: 'Monitor a Website or a YouTube Channel',
        allowed: ['starter', 'standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'We support: Amazon US, UK, CA, AU, DE, ES, MX, BR, IN, and expanding',
        title: 'Check International Stores',
        allowed: ['starter', 'standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'Check your site however often you want: every day, every 90 days or anything in between',
        title: 'Daily Checking and Reporting',
        allowed: ['starter', 'standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'We do not limit the amount of daily/weekly page checks you can do. Your plan is the only limit',
        title: 'Uncapped Daily Page Checks',
        allowed: ['starter', 'standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'Find same products you link out to on alternative stores with affiliate programs: CJ, ShareASale, Skimlinks, Target, Walmart, Wayfair, etc',
        title: 'Find Alternatives To Amazon Products',
        allowed: ['standard', 'portfolio', 'tycoon'],
      },
      {
        text: 'Priority Live Chat & Email support',
        title: 'Priority Support',
        allowed: ['portfolio', 'tycoon'],
      },
    ]

    const Yes = () => <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '16px' }} />
    const No = () => <CloseCircleTwoTone twoToneColor="lightgrey" style={{ fontSize: '16px' }} />
    const LowQuality = () => (
      <CheckCircleTwoTone twoToneColor="lightgrey" style={{ fontSize: '16px' }} />
    )

    return (
      <div className="pricing_features">
        <List
          style={{
            fontSize: '14px !important',
            minWidth: '220px',
            justifyContent: 'left',
          }}
        >
          {features.map((f) => (
            <Item className="pricing_feature" key={JSON.stringify(f)}>
              {f.allowed.includes(plan) ? <Yes /> : <No />}
              <Tooltip title={f.text}>
                <span className="pricing_feature_text">{f.title}</span>
              </Tooltip>
            </Item>
          ))}
        </List>
      </div>
    )
  }

  signupRedirect = () => {
    try {
      var finalUrl = `https://app.amzwatcher.com/sign-up?`
      if (localStorage && localStorage.getItem('campaign')) {
        console.log('found campaign, attaching..', localStorage.getItem('campaign'))
        finalUrl = finalUrl + '&campaign=' + localStorage.getItem('campaign')
      }

      if (localStorage && localStorage.getItem('analyticsId')) {
        console.log('found analyticsId, attaching..', localStorage.getItem('analyticsId'))
        finalUrl = finalUrl + '&analyticsId=' + localStorage.getItem('analyticsId')
      }

      var tracker = window.ga && window.ga.getAll() && window.ga.getAll()[0]
      if (tracker) {
        console.log('tracking successful event')
        tracker.send('event', 'Opt-in', 'Click', 'Try For Free')
      }

      window.location.href = finalUrl
    } catch (e) {
      console.log('error', e)
    }
  }

  render() {
    const { isYearly } = this.state

    const Yes = () => <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '16px' }} />
    const No = () => <CloseCircleTwoTone twoToneColor="lightgrey" style={{ fontSize: '16px' }} />
    const LowQuality = () => (
      <CheckCircleTwoTone twoToneColor="lightgrey" style={{ fontSize: '16px' }} />
    )
    return (
      <div className="pricing" style={{ textAlign: 'center' }}>
        <YearlySwitcher isYearly={isYearly} onChange={(isYearly) => this.setState({ isYearly })} />
        <div className="pricing_grid">
          <div className="pricing_grid_item">
            <div className="pricing_header" style={{ backgroundColor: '#f5f8ff' }}>
              <Title level={2} style={{ margin: '0 0 15px 0' }}>
                {this.getPlanProps('starter').plan}
              </Title>
              <div style={{ fontWeight: 700 }}>5,000 page checks/mo</div>
              <div style={{ fontWeight: 700 }}>3 projects</div>
            </div>
            <div className="pricing_buy">
              <PlanHeader isYearly={isYearly} planProps={this.getPlanProps('starter')} />
              {this.createAction('starter')}
            </div>
            {this.renderPlanFeatures('starter')}
          </div>
          <div className="pricing_grid_item">
            <div className="pricing_header" style={{ backgroundColor: '#e0ffef' }}>
              <Title level={2} style={{ margin: '0 0 15px 0' }}>
                {this.getPlanProps('standard').plan}
              </Title>
              <div style={{ fontWeight: 700 }}>10,000 page checks/mo</div>
              <div style={{ fontWeight: 700 }}>5 projects</div>
            </div>
            <div className="pricing_buy">
              <PlanHeader isYearly={isYearly} planProps={this.getPlanProps('standard')} />
              {this.createAction('standard')}
            </div>
            {this.renderPlanFeatures('standard')}
          </div>
          <div className="pricing_grid_item pricing_grid_item_recommended">
            <span className="pricing_recommended">Most Popular</span>
            <div className="pricing_header" style={{ backgroundColor: '#fff7e5' }}>
              <Title level={2} style={{ margin: '0 0 15px 0' }}>
                {this.getPlanProps('portfolio').plan}
              </Title>
              <div style={{ fontWeight: 700 }}>20,000 page checks/mo</div>
              <div style={{ fontWeight: 700 }}>10 projects</div>
            </div>
            <div className="pricing_buy">
              <PlanHeader isYearly={isYearly} planProps={this.getPlanProps('portfolio')} />
              {this.createAction('portfolio')}
            </div>
            {this.renderPlanFeatures('portfolio')}
          </div>
          <div className="pricing_grid_item">
            <div className="pricing_header" style={{ backgroundColor: '#f5f8ff' }}>
              <Title level={2} style={{ margin: '0 0 15px 0' }}>
                {this.getPlanProps('tycoon').plan}
              </Title>
              <div style={{ fontWeight: 700 }}>50,000 page checks/mo</div>
              <div style={{ fontWeight: 700 }}>Unlimited projects</div>
            </div>
            <div className="pricing_buy">
              <PlanHeader isYearly={isYearly} planProps={this.getPlanProps('tycoon')} />
              {this.createAction('tycoon')}
            </div>
            {this.renderPlanFeatures('tycoon')}
          </div>
        </div>
        <SignupModal
          modalOpen={this.state.modalOpen}
          onClose={this.onClose}
          plan={this.state.plan}
        />
      </div>
    )
  }
}

export default PricingTable
