import React, { Component } from 'react'
import '../../App.css'
import countries from './countries'

import URI from 'urijs'
import { Card, Button, Input, message, Checkbox, Table, Switch, InputNumber, Select } from 'antd'

import { CopyToClipboard } from 'react-copy-to-clipboard'

const { TextArea } = Input
const { Option } = Select

const def =
  'https://www.amazon.com/Backpack-Business-Compartment-Charging-Reflective/dp/B07MG4HN96/ref=as_li_ss_tl?SubscriptionId=AKIAJO7E5OLQ67NVPFZA&ascsubtag=777642968-2-781602830.1557861815'

const serpCTR = {
  1: 35,
  2: 13,
  3: 9.5,
  4: 7.9,
  5: 6.1,
  6: 4.1,
  7: 3.8,
  8: 3.5,
  9: 3,
  10: 2.2,
}

class EarningsCalculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.defaultState,
    }
  }

  defaultState = {
    country: 'US',
    category:
      'Toys, Furniture, Home, Home Improvement, Lawn & Garden, Pets Products, Pantry, Headphones, Beauty, Musical Instruments, Business & Industrial Supplies, Outdoors, Tools, Sports, Baby Products',
    siteCTR: 10,
    amazonConv: 5,
    amazonAOV: 70,
    indirectSales: 50,
    isInternational: true,
    internationalRate: 15,

    volumeMainKw: 5000,
    volumeLongTailKw: 15000,
    avgPosition: 3,
  }

  setDefault = () => {
    this.setState({ ...this.defaultState })
  }

  componentDidUpdate() {
    console.log(this.state)
  }

  ensurePercentage = (value) => {
    console.log('ensuring percentage', value)
    if (value < 0 || !value) return 1
    if (value > 100) return 100
    return value
  }

  getEarnings = () => {
    const {
      country,
      category,
      siteCTR,
      amazonConv,
      amazonAOV,
      indirectSales,
      isInternational,
      internationalRate,
      volumeMainKw,
      volumeLongTailKw,
      avgPosition,
    } = this.state

    const commission = countries[country].rates[category]

    const siteImpressions = volumeMainKw + volumeLongTailKw
    const siteVisitors = (siteImpressions * serpCTR[avgPosition]) / 100
    const amazonClicks = (siteVisitors * siteCTR) / 100
    const amazonPurchases = (amazonClicks * amazonConv) / 100
    const amazonRevenue = amazonPurchases * amazonAOV
    const amazonCommission = (amazonRevenue * commission) / 100
    const amazonCommissionInternational = isInternational
      ? (amazonCommission * internationalRate) / 100
      : 0
    const totalComission = amazonCommission + amazonCommissionInternational

    const earningsPerSession = this.formatNumber(amazonCommission / siteVisitors, 5)
    const earningsPerClick = this.formatNumber(amazonCommission / amazonClicks, 5)
    const earningsPerSale = this.formatNumber(amazonCommission / amazonPurchases, 5)

    console.log({
      siteImpressions,
      siteVisitors,
      amazonClicks,
      amazonPurchases,
      amazonRevenue,
      amazonCommission,
      amazonCommissionInternational,
      totalComission,
    })
    // debugger;
    return {
      earningsPerSession,
      earningsPerClick,
      earningsPerSale,

      siteVisitors,
      amazonClicks,
      amazonPurchases,
      amazonRevenue,
      amazonCommission,
      amazonCommissionInternational,
      totalComission,
    }
  }

  formatNumber = (n, toFixed = 0) => {
    if (!!n && n !== Infinity) return Number(n).toFixed(toFixed)
    return 0
  }

  formatCurrency = (n) => (!!n ? `$ ${this.formatNumber(n)}` : `$ 0`)

  renderTable = () => {
    const {
      earningsPerSession,
      earningsPerClick,
      earningsPerSale,

      siteVisitors,
      amazonClicks,
      amazonPurchases,
      amazonRevenue,
      amazonCommission,
      amazonCommissionInternational,
      totalComission,
    } = this.getEarnings()

    const dataSource = [
      {
        name: 'Site Visitors',
        basic: this.formatNumber(siteVisitors),
        1: this.formatNumber((1000 / totalComission) * siteVisitors),
        3: this.formatNumber((3000 / totalComission) * siteVisitors),
        5: this.formatNumber((5000 / totalComission) * siteVisitors),
        10: this.formatNumber((10000 / totalComission) * siteVisitors),
        25: this.formatNumber((25000 / totalComission) * siteVisitors),
      },
      {
        name: 'Clicks to Amazon',
        basic: this.formatNumber(amazonClicks),
        1: this.formatNumber((1000 / totalComission) * amazonClicks),
        3: this.formatNumber((3000 / totalComission) * amazonClicks),
        5: this.formatNumber((5000 / totalComission) * amazonClicks),
        10: this.formatNumber((10000 / totalComission) * amazonClicks),
        25: this.formatNumber((25000 / totalComission) * amazonClicks),
      },
      {
        name: 'Sales on Amazon (direct)',
        basic: this.formatCurrency(amazonRevenue),
        1: this.formatCurrency((1000 / totalComission) * amazonRevenue),
        3: this.formatCurrency((3000 / totalComission) * amazonRevenue),
        5: this.formatCurrency((5000 / totalComission) * amazonRevenue),
        10: this.formatCurrency((10000 / totalComission) * amazonRevenue),
        25: this.formatCurrency((25000 / totalComission) * amazonRevenue),
      },
      {
        name: 'Sales on Amazon (indirect)',
        basic: this.formatCurrency(0),
        1: this.formatCurrency((1000 / totalComission) * 0),
        3: this.formatCurrency((3000 / totalComission) * 0),
        5: this.formatCurrency((5000 / totalComission) * 0),
        10: this.formatCurrency((10000 / totalComission) * 0),
        25: this.formatCurrency((25000 / totalComission) * 0),
      },
      {
        name: 'Commissions on Amazon US',
        basic: this.formatCurrency(amazonCommission),
        1: this.formatCurrency((1000 / totalComission) * amazonCommission),
        3: this.formatCurrency((3000 / totalComission) * amazonCommission),
        5: this.formatCurrency((5000 / totalComission) * amazonCommission),
        10: this.formatCurrency((10000 / totalComission) * amazonCommission),
        25: this.formatCurrency((25000 / totalComission) * amazonCommission),
      },
      {
        name: 'Commissions on Amazon Intl.',
        basic: this.formatCurrency(amazonCommissionInternational),
        1: this.formatCurrency((1000 / totalComission) * amazonCommissionInternational),
        3: this.formatCurrency((3000 / totalComission) * amazonCommissionInternational),
        5: this.formatCurrency((5000 / totalComission) * amazonCommissionInternational),
        10: this.formatCurrency((10000 / totalComission) * amazonCommissionInternational),
        25: this.formatCurrency((25000 / totalComission) * amazonCommissionInternational),
      },
      {
        name: 'Total Commissions',
        basic: this.formatCurrency(totalComission),
        1: this.formatCurrency((1000 / totalComission) * totalComission),
        3: this.formatCurrency((3000 / totalComission) * totalComission),
        5: this.formatCurrency((5000 / totalComission) * totalComission),
        10: this.formatCurrency((10000 / totalComission) * totalComission),
        25: this.formatCurrency((25000 / totalComission) * totalComission),
      },
    ]

    const columns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Your Site',
        dataIndex: 'basic',
        key: 'basic',
      },
      {
        title: '$1k / mo',
        dataIndex: '1',
        key: '1',
      },
      {
        title: '$3k / mo',
        dataIndex: '3',
        key: '3',
      },
      {
        title: '$5k / mo',
        dataIndex: '5',
        key: '5',
      },
      {
        title: '$10k / mo',
        dataIndex: '10',
        key: '10',
      },
      {
        title: '$25k / mo',
        dataIndex: '25',
        key: '25',
      },
    ]

    return <Table dataSource={dataSource} columns={columns} pagination={false} size="small" />
  }

  render() {
    const {
      country,
      category,
      siteCTR,
      amazonConv,
      amazonAOV,
      indirectSales,
      isInternational,
      internationalRate,
      volumeMainKw,
      volumeLongTailKw,
      avgPosition,
    } = this.state

    const { earningsPerClick, earningsPerSale, earningsPerSession } = this.getEarnings()
    return (
      <div className="widget-form">
        <Card
          title={<span>Amazon Affiliate Earning Calculator</span>}
          headStyle={{ fontSize: 20, textAlign: 'center' }}
          className="widget-card"
          style={{ width: '90%', textAlign: 'left' }}
        >
          <div style={{ margin: '20px auto', width: 600, textAlign: 'center' }}>
            <div style={{ margin: '10px 0' }}>
              <div style={{ width: 125, display: 'inline-block', marginRight: '10px' }}>
                Amazon Country
              </div>
              <Select
                style={{ width: 220 }}
                value={country}
                onChange={(country) => this.setState({ country })}
              >
                {Object.keys(countries).map((k) => {
                  const country = countries[k]
                  return (
                    <Option value={k} key={k} disabled={!country.isEnabled}>
                      <img alt={k} className="site-manager-flag" src={country.flagUrl} />
                      {country.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div>
              <div style={{ width: 125, display: 'inline-block', marginRight: '10px' }}>
                Amazon Category
              </div>
              <Select
                style={{ width: 450 }}
                value={category}
                onChange={(category) => this.setState({ category })}
                showSearch
              >
                {Object.keys(countries[country].rates).map((k) => {
                  const category = countries[country].rates[k]
                  return (
                    <Option value={k} key={k} title={`${k} - ${category} %`}>
                      {k} - {category} %
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className="earnings-calculator-input-data">
            <div className="earnings-calculator-traffic">
              <h5>Your Site Data</h5>
              <div>
                <span>Website CTR To Amazon</span>
                <InputNumber
                  min={1}
                  max={100}
                  value={siteCTR || 0}
                  onChange={(siteCTR) => this.setState({ siteCTR: this.ensurePercentage(siteCTR) })}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </div>
              <div>
                <span>Amazon Purchase Conversion Rate</span>
                <InputNumber
                  min={1}
                  max={100}
                  value={amazonConv || 0}
                  onChange={(amazonConv) =>
                    this.setState({ amazonConv: this.ensurePercentage(amazonConv) })
                  }
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </div>
              <div>
                <span> Average Sale on Amazon</span>
                <InputNumber
                  min={1}
                  max={100000}
                  value={amazonAOV || 1}
                  onChange={(amazonAOV) =>
                    this.setState({ amazonAOV: amazonAOV > 1 ? amazonAOV : 1 })
                  }
                  formatter={(value) => `$${value}`}
                  parser={(value) => value.replace('$', '')}
                />
              </div>
              <div style={{ display: 'none' }}>
                <span>Percentage of Indirect Sales</span>
                <InputNumber
                  min={1}
                  max={100}
                  value={indirectSales}
                  onChange={(indirectSales) =>
                    this.setState({ indirectSales: this.ensurePercentage(indirectSales) })
                  }
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                  readOnly
                />
              </div>
              <div>
                <span>Add International Sales</span>
                <Switch
                  checked={isInternational}
                  onChange={(isInternational) => this.setState({ isInternational })}
                />
              </div>
              <div>
                <span>Additional International Sales</span>
                <InputNumber
                  min={1}
                  max={100}
                  value={internationalRate}
                  onChange={(internationalRate) =>
                    this.setState({ internationalRate: this.ensurePercentage(internationalRate) })
                  }
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </div>
            </div>
            <div>
              <div className="earnings-calculator-traffic">
                <h5>Monthly Organic Traffic Data</h5>
                <div>
                  <span>Main keyword Searches</span>
                  <InputNumber
                    min={1}
                    value={volumeMainKw}
                    onChange={(volumeMainKw) => this.setState({ volumeMainKw })}
                  />
                </div>
                <div>
                  <span>Long Tail Keywords</span>
                  <InputNumber
                    value={volumeLongTailKw}
                    onChange={(volumeLongTailKw) => this.setState({ volumeLongTailKw })}
                  />
                </div>
                <div>
                  <span>Average SERP Position</span>
                  <InputNumber
                    min={1}
                    max={100}
                    value={avgPosition}
                    onChange={(avgPosition) => this.setState({ avgPosition })}
                  />
                </div>
                <div>
                  <span>SERP CTR</span>
                  <InputNumber
                    readOnly
                    value={serpCTR[avgPosition]}
                    formatter={(value) => `${value}%`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="earnings-calculator-table">
            <h5 style={{ textAlign: 'center' }}>Amazon Affiliate Earnings Estimates</h5>
            {this.renderTable()}
          </div>

          <div style={{ margin: '40px auto', width: 370 }}>
            <div className="earnings-calculator-traffic">
              <h5 style={{ textAlign: 'center' }}>Unit Ecomonics</h5>
              <div>
                <span>Earnings per session</span>
                <InputNumber
                  readOnly
                  value={earningsPerSession || 0}
                  formatter={(value) => `$ ${value}`}
                />
              </div>
              <div>
                <span>Earnings per click (to amazon)</span>
                <InputNumber
                  readOnly
                  value={earningsPerClick || 0}
                  formatter={(value) => `$ ${value}`}
                />
              </div>
              <div>
                <span> Earnings per sale </span>
                <InputNumber
                  readOnly
                  value={earningsPerSale || 0}
                  formatter={(value) => `$ ${value}`}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    )
  }
}

export default EarningsCalculator
