import React, { Component } from 'react'

import { Select, Card } from 'antd'

const { Option } = Select

class Calc extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: 50,
      rate: 8,
      websites: 2,
      links: 5,
      timePerLink: 40,
      cadence: 7,
    }
  }

  getCurrentPlanName = () => {
    const { websites } = this.state
    const checks = this.getRequiredMonthyChecks()
    console.log('checks', checks)
    // const isOneWebsites = websites <= 3;
    const starterSites = 3
    const standardSites = 5
    const portfolioProjects = 10

    const needsStarterSites = websites <= starterSites
    const needsStandardSites = websites > starterSites && websites <= standardSites
    const needsPortfolioSites = websites > standardSites && websites <= portfolioProjects
    const needsTycoonSites = websites > portfolioProjects

    console.log(needsStarterSites, needsPortfolioSites)
    const starterChecks = 5000
    const standardChecks = 10000
    const portfolioChecks = 20000

    const needsStarterChecks = checks <= starterChecks
    const needsStandardChecks = checks > starterChecks && checks <= standardChecks
    const needsPortfolioChecks = checks > standardChecks && checks <= portfolioChecks
    const needsTycoonChecks = checks > portfolioChecks
    console.log(needsStarterChecks, needsStandardChecks, needsPortfolioChecks, needsTycoonChecks)
    console.log('========')

    if (needsTycoonChecks || needsTycoonSites) return 'Tycoon'
    if (needsPortfolioSites || needsPortfolioChecks) return 'Portfolio'
    if (needsStandardChecks || needsStandardSites) return 'Standard'
    return 'Starter'
  }

  getCurrentPlanCost = () => {
    const plan = this.getCurrentPlanName()
    if (plan === 'Standard') return 34.95
    if (plan === 'Portfolio') return 49.95
    if (plan === 'Tycoon') return 124.95
    return 19.95
  }

  getRequiredMonthyChecks = () => {
    const { links, websites, pages, cadence } = this.state
    return links * pages * websites * (30 / cadence)
  }

  getMonthlyMoneySpend = () => {
    const { rate, timePerLink } = this.state
    return ((this.getRequiredMonthyChecks() * timePerLink) / 3600) * rate
  }

  getResult = () => {
    const { links, rate, websites, pages, timePerLink, cadence } = this.state
    return ((links * timePerLink * pages * websites) / 3600) * rate * (30 / cadence)
  }

  render() {
    const { links, rate, websites, pages, cadence } = this.state

    const websiteSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(websites) => this.setState({ websites })}
        value={websites}
        size="small"
      >
        {Array(50)
          .fill(0)
          .map((c, i) =>
            i > 0 ? (
              <Option value={i} key={i}>
                {i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const pagesSelector = (
      <Select
        style={{ width: 80, minWidth: 80 }}
        onChange={(pages) => this.setState({ pages })}
        value={pages}
        size="small"
      >
        <Option value={10} key={10}>
          10
        </Option>
        <Option value={15} key={15}>
          15
        </Option>
        {Array(500)
          .fill(0)
          .map((c, i) =>
            i > 1 ? (
              <Option value={i * 10} key={i}>
                {i * 10}
              </Option>
            ) : null
          )}
      </Select>
    )

    const rateSelector = (
      <Select
        style={{ width: 70, minWidth: 70 }}
        onChange={(rate) => this.setState({ rate })}
        value={rate}
        size="small"
      >
        {Array(200)
          .fill(0)
          .map((c, i) =>
            i > 3 ? (
              <Option value={i} key={i}>
                ${i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const linksSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(links) => this.setState({ links })}
        value={links}
        size="small"
      >
        {Array(21)
          .fill(0)
          .map((c, i) =>
            i > 0 ? (
              <Option value={i} key={i}>
                {i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const cadenceSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(cadence) => this.setState({ cadence })}
        value={cadence}
        size="small"
      >
        <Option value={1}>{1}</Option>
        <Option value={3}>{3}</Option>
        <Option value={5}>{5}</Option>
        <Option value={7}>{7}</Option>
        <Option value={14}>{14}</Option>
        <Option value={28}>{28}</Option>
      </Select>
    )
    const addS = this.state.websites > 1
    console.log('current price', this.getCurrentPlanCost())

    const savings = Math.trunc(this.getMonthlyMoneySpend() - this.getCurrentPlanCost())
    console.log('savings', savings)
    return (
      <div className="widget-form">
        <Card
          title={
            <span>
              OK. I'm convinced of checking my links, but why pay for another software tool?
              <br />
              Calculate if it really makes sense to you time-wise.
            </span>
          }
          headStyle={{ fontSize: 20, textAlign: 'center' }}
          className="widget-card"
        >
          <span style={{ marginBottom: 20, display: 'block', lineHeight: '30px' }}>
            I run {websiteSelector} website{addS && 's'} with {pagesSelector} commercial articles{' '}
            {websites > 1 && '(per site on average)'}. An average commercial article has{' '}
            {linksSelector} unique amazon links. I want to check links every {cadenceSelector} days.
            <br />
          </span>
          <span>
            If my (time/VA) costs me {rateSelector} per hour, I'll end up spending{' '}
            <span style={{ fontWeight: 600 }}>
              {Math.trunc(this.getMonthlyMoneySpend())}$ every month.
            </span>
            <br />
          </span>
          {/* <span>
                Start saving {Math.trunc(this.getResult() - 19.95)}$ in your time only..
            </span> */}
          <br />
          {savings <= 0 && (
            <span>
              Looks like you need a slightly bigger site portfolio <br /> to make AMZ Watcher worth
              your while!
            </span>
          )}
          {savings > 0 && (
            <div>
              <div>
                The best plan for you is {this.getCurrentPlanName()} @ ${this.getCurrentPlanCost()}
              </div>
              <br />
              <h5>Start saving ${savings}/month</h5>
            </div>
          )}

          <span className="button-wrapper">
            <a
              href="https://app.amzwatcher.com/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              className="button-action"
            >
              <span>Try For Free</span>
            </a>
          </span>
          <br />
          <br />
          <span style={{ color: 'grey', fontSize: 13 }}>
            Note: <br />
            * This does not cover the cost of managing and controlling results of the whole process.{' '}
            <br />
            * This does not take an increase in earnings from recovering links into account <br />*
            We can check links for you daily instead of every {cadence} days <br />
          </span>
        </Card>
      </div>
    )
  }
}

export default Calc
