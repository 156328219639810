import React, { Component } from 'react'
import axios from 'axios'

import { Typography, Modal, Button, Form, Input } from 'antd'

import { MailOutlined, GlobalOutlined } from '@ant-design/icons'

const { Title } = Typography

class EmailOptinForm extends Component {
  handleSubmit = (values) => {
    const { onSubmit } = this.props
    onSubmit(values)
  }

  validateSiteUrl = (rule, value) => {
    if (
      value &&
      (value.indexOf('http:') > -1 ||
        value.indexOf('https:') > -1 ||
        value.indexOf('.') === -1 ||
        value.indexOf('/') > -1 ||
        value[value.length - 1] === '/')
    ) {
      return Promise.reject('URL should look like: example.com')
    } else {
      return Promise.resolve()
    }
  }

  render() {
    return (
      <Form onFinish={this.handleSubmit} className="login-form">
        <Form.Item
          label=""
          className="login-form-item"
          name="email"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'Plase input a valid email' },
          ]}
        >
          <Input
            style={{ height: '40px' }}
            className="landing-search-input"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Your Email"
            size="large"
          />
        </Form.Item>
        <Form.Item
          label=""
          className="login-form-item"
          name="siteUrl"
          rules={[
            { required: true, message: 'Please input your website' },
            { validator: this.validateSiteUrl },
          ]}
        >
          <Input
            addonBefore="https://"
            style={{ height: '40px' }}
            addonAfter="/"
            className="login-form-input"
            prefix={<GlobalOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Website Url"
            size="large"
          />
        </Form.Item>
        <Form.Item className="login-form-item">
          <Button
            role="button"
            style={{ padding: 0, borderRadius: '3px' }}
            className="core-optin-cta"
            onClick={this.signup}
            htmlType="submit"
          >
            See Report
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

class SignupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalOpen !== this.props.modalOpen) {
      this.setState({ isModalVisible: this.props.modalOpen })
    }
  }

  // componentDidMount() {
  //   const els = document.querySelectorAll('.pricing__button');
  //   els.forEach(e => {
  //     e.addEventListener('click', this.openModal);
  //   })
  // }

  // componentWillUnmount() {
  //   const els = document.querySelectorAll('.pricing__button');
  //   els.forEach(e => {
  //     e.removeEventListener('click', this.openModal);
  //   })
  // }

  onSubmit = async (values) => {
    console.log('values', values)
    const { email, siteUrl } = values
    await this.saveLead(siteUrl, email)
    this.redirectToSite(siteUrl, email)
  }

  redirectToSite = (url, email) => {
    try {
      var parsedUrl = null
      // debugger;
      if (url.indexOf('https://') > -1 || url.indexOf('http://') > -1) {
        parsedUrl = new URL(url).host
      } else {
        parsedUrl = new URL('https://' + url).host
      }

      if (!parsedUrl || parsedUrl.indexOf('.') === -1) throw new Error('no parsedUrl')
      //   var finalUrl = `https://app.amzwatcher.com/sign-up?siteUrl=${parsedUrl}&email=${email}`;
      var finalUrl = `https://app.amzwatcher.com/start/${parsedUrl}?source=signupOptin&email=${email}`
      if (localStorage && localStorage.getItem('campaign')) {
        finalUrl = finalUrl + '&campaign=' + localStorage.getItem('campaign')
      }
      console.log('extracted results, redirecting...', finalUrl)
      window.location.href = finalUrl
    } catch (e) {
      console.log('error', e)
    }
  }

  saveLead = async (siteUrl, email) => {
    try {
      let campaign = ''
      if (localStorage && localStorage.getItem('campaign')) {
        campaign = localStorage.getItem('campaign')
      }
      await axios.put(`https://app.amzwatcher.com/api/users/addToList/`, {
        email,
        siteUrl,
        source: 'landing',
        campaign,
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  render() {
    console.log('rendering signup modal comp')
    return (
      <div>
        <Modal
          // title="Optin Modal"
          className="core-optin-modal"
          visible={this.state.isModalVisible}
          onOk={null}
          onCancel={() => this.setState({ isModalVisible: false }, () => this.props.onClose())}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: true }}
          footer={null}
          header={null}
          width={800}
          style={{ marginTop: '20vh', textAlign: 'center' }}
        >
          <Title level={3} style={{ marginBottom: 10, marginTop: 20 }}>
            Get Started With A Free Report For Your Site
          </Title>
          <div
            style={{
              fontSize: '16px',
              width: '60%',
              margin: '10px auto 0 auto',
            }}
          >
            Create an account and get a free demo report of your site
            <br />
            <br />
            <EmailOptinForm onSubmit={this.onSubmit} />
          </div>
        </Modal>
      </div>
    )
  }
}

export default SignupModal
