const countries = {
  US: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/US-flag._V192206995_.gif',
    name: 'United States',
    isEnabled: true,
    rates: {
      'Luxury Beauty, Amazon Coins': 10.0,
      'Digital Music, Physical Music, Handmade, Digital Videos': 5.0,
      'Physical Books, Kitchen, Automotive': 4.5,
      'Amazon Fire Tablet Devices, Amazon Kindle Devices, Amazon Fashion Womens, Mens & Kids Private Label, Apparel, Amazon Cloud Cam Devices, Fire TV Edition Smart TVs, Amazon Fire TV Devices, Amazon Echo Devices, Ring Devices, Watches, Jewelry, Luggage, Shoes, and Handbags & Accessories': 4.0,
      'Toys, Furniture, Home, Home Improvement, Lawn & Garden, Pets Products, Pantry, Headphones, Beauty, Musical Instruments, Business & Industrial Supplies, Outdoors, Tools, Sports, Baby Products': 3.0,
      'PC, PC Components, DVD & Blu-Ray': 2.5,
      'Televisions, Digital Video Games': 2.0,
      'Amazon Fresh, Physical Video Games & Video Game Consoles, Grocery, Health & Personal Care': 1.0,
      'Gift Cards; Wireless Service Plans; Alcoholic Beverages; Digital Kindle Products purchased as a subscription; Food prepared and delivered from a restaurant; Amazon Appstore, Prime Now, Amazon Pay Places, or Prime Wardrobe  Purchases': 0.0,
      'All Other Categories': 4.0,
    },
  },
  GB: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/UK-flag._V192206995_.gif',
    name: 'United Kingdom',
    isEnabled: false,
  },
  DE: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/DE-flag._V192206995_.gif',
    name: 'Germany',
    isEnabled: false,
  },
  FR: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/FR-flag._V192206995_.gif',
    name: 'France',
    isEnabled: false,
  },
  ES: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/ES-flag._V192206995_.gif',
    name: 'Spain',
    isEnabled: false,
  },
  CA: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/CA-flag._V192206995_.gif',
    name: 'Canada',
    isEnabled: false,
  },
  AU: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/AU-flag._V192206995_.gif',
    name: 'Australia',
    isEnabled: false,
  },
  IT: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/IT-flag._V192206995_.gif',
    name: 'Italy',
    isEnabled: false,
  },
  NL: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/NL-flag._V192206995_.gif',
    name: 'Netherlands',
    isEnabled: false,
  },
  IN: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/IN-flag._V192206995_.gif',
    name: 'India',
    isEnabled: false,
  },
  BR: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/BR-flag._V192206995_.gif',
    name: 'Brazil',
    isEnabled: false,
  },
  MX: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/MX-flag._V192206995_.gif',
    name: 'Mexico',
    isEnabled: false,
  },
  UAE: {
    flagUrl:
      'https://images-eu.ssl-images-amazon.com/images/G/39/associates/network/UAEFlag._CB1198675309_.png',
    name: 'United Arab Emirates',
    isEnabled: false,
  },
  JP: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/JP-flag._V192206995_.gif',
    name: 'Japan',
    isEnabled: false,
  },
  CN: {
    flagUrl:
      'https://images-na.ssl-images-amazon.com/images/G/01/associates/network/CN-flag._V192206995_.gif',
    name: 'China',
    isEnabled: false,
  },
}

module.exports = countries
