import React, { Component } from 'react'

import './PricingTable.css'

class YearlySwitcher extends Component {
  state = {
    isYearly: true,
  }

  onToggleYearly = () => {
    this.props.onChange(!this.state.isYearly)
    this.setState({ isYearly: !this.state.isYearly })
  }

  render() {
    const { isYearly } = this.state

    return (
      <div className="pricing__switcher__tabs">
        <div
          className={`pricing__switcher__tab monthly${!isYearly ? ' active' : ''}`}
          onClick={this.onToggleYearly}
        >
          <span className="type">Monthly billing</span>
        </div>
        <div
          className={`pricing__switcher__tab annual${isYearly ? ' active' : ''}`}
          onClick={this.onToggleYearly}
        >
          <span className="type">Annual billing &nbsp;2 months FREE</span>
        </div>
      </div>
    )
  }
}

export default YearlySwitcher
