import React from 'react'

import './PricingTable.css'

const PlanHeader = (props) => {
  const { isYearly, planProps } = props
  return (
    <div className="plan_header_container">
      <span style={{ fontSize: '18px', fontWeight: 700, color: 'rgba(0, 0, 0, 0.85)' }}>
        ${(planProps.price / 100).toFixed(2)}
      </span>
      <span style={{ fontSize: '15px', lineHeight: 2, color: 'grey' }}> / month</span>
      <br />
      <span style={{ fontSize: '15px', lineHeight: 2, display: 'block', color: 'grey' }}>
        billed {isYearly ? 'yearly' : 'monthly'}
      </span>
      <div style={{ marginBottom: 10 }}>
        {isYearly && (
          <span className="pricing-saving">
            save ${(((planProps.price * 12) / 1000) * 2).toFixed(2)}
          </span>
        )}
      </div>
    </div>
  )
}

export default PlanHeader
