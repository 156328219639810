import React, { Component } from 'react'

import { Select, Card, InputNumber } from 'antd'

const { Option } = Select

class RecoveryCalc extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: 30,
      rate: 8,
      websites: 2,
      links: 5,
      timePerLink: 40,
      cadence: 7,
      categoryRate: 5.5,
      averageRevenue: 2000,
      brokenLinkPercentage: 10,
    }
  }

  getCurrentPlanName = () => {
    const { websites } = this.state
    const checks = this.getRequiredMonthyChecks()
    console.log('checks', checks)
    // const isOneWebsites = websites <= 3;
    const starterSites = 3
    const standardSites = 5
    const portfolioProjects = 10

    const needsStarterSites = websites <= starterSites
    const needsStandardSites = websites > starterSites && websites <= standardSites
    const needsPortfolioSites = websites > standardSites && websites <= portfolioProjects
    const needsTycoonSites = websites > portfolioProjects

    console.log(needsStarterSites, needsPortfolioSites)
    const starterChecks = 5000
    const standardChecks = 10000
    const portfolioChecks = 20000

    const needsStarterChecks = checks <= starterChecks
    const needsStandardChecks = checks > starterChecks && checks <= standardChecks
    const needsPortfolioChecks = checks > standardChecks && checks <= portfolioChecks
    const needsTycoonChecks = checks > portfolioChecks
    console.log(needsStarterChecks, needsStandardChecks, needsPortfolioChecks, needsTycoonChecks)
    console.log('========')

    if (needsTycoonChecks || needsTycoonSites) return 'Tycoon'
    if (needsPortfolioSites || needsPortfolioChecks) return 'Portfolio'
    if (needsStandardChecks || needsStandardSites) return 'Standard'
    return 'Starter'
  }

  getCurrentPlanCost = () => {
    const plan = this.getCurrentPlanName()
    if (plan === 'Standard') return 34.95
    if (plan === 'Portfolio') return 49.95
    if (plan === 'Tycoon') return 124.95
    return 19.95
  }

  getRequiredMonthyChecks = () => {
    const { links, websites, pages, cadence } = this.state
    return links * pages * websites * (30 / cadence)
  }

  getMonthlyMoneyLost = () => {
    const { averageRevenue, brokenLinkPercentage } = this.state
    return (averageRevenue * brokenLinkPercentage) / 100
  }

  getResult = () => {
    const { links, rate, websites, pages, timePerLink, cadence } = this.state
    return ((links * timePerLink * pages * websites) / 3600) * rate * (30 / cadence)
  }

  render() {
    const {
      links,
      rate,
      websites,
      pages,
      cadence,
      averageRevenue,
      categoryRate,
      brokenLinkPercentage,
    } = this.state

    const websiteSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(websites) => this.setState({ websites })}
        value={websites}
        size="small"
      >
        {Array(50)
          .fill(0)
          .map((c, i) =>
            i > 0 ? (
              <Option value={i} key={i}>
                {i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const pagesSelector = (
      <Select
        style={{ width: 80, minWidth: 80 }}
        onChange={(pages) => this.setState({ pages })}
        value={pages}
        size="small"
      >
        <Option value={10} key={10}>
          10
        </Option>
        <Option value={15} key={15}>
          15
        </Option>
        {Array(500)
          .fill(0)
          .map((c, i) =>
            i > 1 ? (
              <Option value={i * 10} key={i}>
                {i * 10}
              </Option>
            ) : null
          )}
      </Select>
    )

    const rateSelector = (
      <Select
        style={{ width: 70, minWidth: 70 }}
        onChange={(rate) => this.setState({ rate })}
        value={rate}
        size="small"
      >
        {Array(200)
          .fill(0)
          .map((c, i) =>
            i > 3 ? (
              <Option value={i} key={i}>
                ${i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const linksSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(links) => this.setState({ links })}
        value={links}
        size="small"
      >
        {Array(21)
          .fill(0)
          .map((c, i) =>
            i > 0 ? (
              <Option value={i} key={i}>
                {i}
              </Option>
            ) : null
          )}
      </Select>
    )

    const cadenceSelector = (
      <Select
        style={{ width: 60, minWidth: 60 }}
        onChange={(cadence) => this.setState({ cadence })}
        value={cadence}
        size="small"
      >
        <Option value={1}>{1}</Option>
        <Option value={3}>{3}</Option>
        <Option value={5}>{5}</Option>
        <Option value={7}>{7}</Option>
        <Option value={14}>{14}</Option>
        <Option value={28}>{28}</Option>
      </Select>
    )

    const categoryRateSelector = (
      <Select
        style={{ width: 300, minWidth: 300 }}
        onChange={(categoryRate) => this.setState({ categoryRate })}
        value={categoryRate}
        size="small"
      >
        <Option value={1}>1% - Video Games & Video Game Consoles</Option>
        <Option value={2}>2% - Televisions</Option>
        <Option value={2.5}>2.5% - PC, PC Components, DVD & Blu-Ray</Option>
        <Option value={3}>3% - Toys</Option>
        <Option value={4}>
          4% - Amazon Kindle Devices, Amazon Fire Tablet Devices, All Other Categories
        </Option>
        <Option value={4.5}>
          4.5% - Physical Books, Grocery, Physical Music, Handmade, Digital Videos
        </Option>
        <Option value={5.5}>5.5% - Outdoors, Tools</Option>
        <Option value={6}>
          6% - Headphones, Beauty, Musical Instruments, Business & Industrial Supplies
        </Option>
        <Option value={7}>
          7% - Apparel, Jewelry, Luggage, Shoes, Handbags & Accessories, Watches, Amazon Echo
          Devices, Amazon Fire TV Devices
        </Option>
        <Option value={8}>
          8% - Furniture, Home, Home Improvement, Lawn & Garden, Pets Products, Pantry
        </Option>
        <Option value={10}>10% - Digital Video Games, Luxury Beauty, Amazon Coins</Option>
      </Select>
    )

    const brokenLinkPercentageSelector = (
      <Select
        style={{ width: 70, minWidth: 70 }}
        onChange={(brokenLinkPercentage) => this.setState({ brokenLinkPercentage })}
        value={brokenLinkPercentage}
        size="small"
      >
        <Option value={1}>{1}%</Option>
        <Option value={2}>{2}%</Option>
        <Option value={5}>{5}%</Option>
        <Option value={10}>{10}%</Option>
        <Option value={15}>{15}%</Option>
        <Option value={20}>{20}%</Option>
      </Select>
    )

    const revenueInput = (
      <InputNumber
        defaultValue={averageRevenue}
        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        onChange={(averageRevenue) => this.setState({ averageRevenue })}
        size="small"
      />
    )

    const addS = this.state.websites > 1
    console.log('current price', this.getCurrentPlanCost())

    const savings = Math.trunc(this.getMonthlyMoneyLost())
    console.log('savings', savings)

    const losingMoney = Math.trunc((averageRevenue * brokenLinkPercentage) / 100)
    return (
      <div className="widget-form">
        <Card
          title={<span>How much money could I be losing from broken Amazon links?</span>}
          headStyle={{ fontSize: 20, textAlign: 'center' }}
          className="widget-card"
        >
          <div style={{ display: 'block', lineHeight: '30px' }}>
            I run {websiteSelector} website{addS && 's'} with {pagesSelector} commercial articles{' '}
            {websites > 1 && '(per site on average)'}.&nbsp;
            {/* <br /> */}
            An average commercial article has {linksSelector} unique amazon links. &nbsp;
            {/* <br /> */}I want to check links every {cadenceSelector} days.
          </div>
          <br />
          <div>
            My website potfolio makes {revenueInput} per month on average. If{' '}
            {brokenLinkPercentageSelector} of my affiliate links are broken, I am losing{' '}
            <b>${losingMoney}</b> every month
          </div>
          <br />
          <span style={{ fontSize: '20px', margin: '10px 0 20px 0', fontWeight: '700' }}>
            Results:
          </span>
          {savings <= 0 && (
            <div>Try checking the links less often to see higher benefit from AMZ Watcher</div>
          )}
          {savings > 0 && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  border: '1px solid gainsboro',
                  padding: 10,
                  boxShadow: '0 0 13px -10px',
                  margin: '20px 0',
                }}
              >
                <span style={{ fontSize: '15px', fontWeight: '600', margin: '10px 10px 0 10px' }}>
                  Estimated Monthly Revenue Increase{' '}
                  <span style={{ color: 'rgb(15, 197, 74)', fontWeight: '700', display: 'block' }}>
                    ${savings} / month
                  </span>
                </span>
                <br />
                <span style={{ fontSize: '15px', fontWeight: '600', margin: '10px 0' }}>
                  Estimated Site Valuation Increase{' '}
                  <span style={{ color: 'rgb(15, 197, 74)', fontWeight: '700', display: 'block' }}>
                    ${this.getMonthlyMoneyLost() * 35}
                  </span>
                </span>
                <br />
              </div>
              <span style={{ fontSize: '15px', fontWeight: '600', margin: '10px 0' }}>
                The best plan for your portfolio is{' '}
                <span style={{ color: 'rgb(15, 197, 74)', fontWeight: '700', display: 'block' }}>
                  {this.getCurrentPlanName()} @ ${this.getCurrentPlanCost()} / month
                </span>
              </span>
            </div>
          )}
          <span className="button-wrapper">
            <a
              href="https://app.amzwatcher.com/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              className="button-action"
            >
              <span>Sign Up Now</span>
            </a>
          </span>
          <br />
          <br />
          <span style={{ color: 'grey', fontSize: 13 }}>
            Full Disclaimer: <br />
            * This calculator is for educational purposes only. The results will be different for
            every customer. <br />
            * This calculator does not take into account the work required to change the links.{' '}
            <br />
            * We use a 35x multiple to calculate your site's valuation <br />
          </span>
        </Card>
      </div>
    )
  }
}

export default RecoveryCalc
